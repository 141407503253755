import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    bgcolor: "white",
    user: {
      username: "",
      password: "",
    },
    loginStatus: false,
    // pageid: "106004118709627", //Test GPF
    pageid: "150765918293329",
    id: "",
    chatlog: [],
    registerData: [],
    recaptchaToken: null,
  },
  mutations: {
    ["CHAT_LOG"](state, payload) {
      // mutate state
      state.chatlog = payload;
    },
  },
  actions: {
    setChatLog({ commit }, payload) {
      commit("CHAT_LOG", payload);
    },
  },
  modules: {},
});
