import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
import sweetalert from "sweetalert2";
import mainMixin from "./mainMixin.js";
import VueTruncate from "vue-truncate-filter";
import VueSanitize from "vue-sanitize";
import "./vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueTheMask from "vue-the-mask";

import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import { PerfectScrollbar } from "vue2-perfect-scrollbar";
Vue.component("PerfectScrollbar", PerfectScrollbar);

var defaultOptions = {
  allowedTags: ["a", "br"],
  allowedAttributes: {
    a: ["href"],
  },
};
Vue.use(VueSanitize, defaultOptions);
Vue.use(VueTruncate);
Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.prototype.$swal = sweetalert;
Vue.mixin(mainMixin);

new Vue({
  router,
  created() {
    return (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "Messenger");
  },
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
