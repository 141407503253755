
export default {
  methods: {
    isURL: function (str) {
      var pattern = new RegExp(
        /^(ftp|http|https|line):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      ) // fragment locator
      return pattern.test(str)
    },
  }
}
