import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import th from 'vee-validate/dist/locale/th';


// loop over all rules
for (let rule in rules) {
    var data  = {
        ...rules[rule], // add the rule
        message: th.messages[rule] // add its message
    }

    extend(rule, data);
}

extend('isUrl', value => {
    var pattern = new RegExp(
        /^(ftp|http|https|line):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    ) // fragment locator
    return pattern.test(value)
})


extend('thai_idcard', 
{
    message: 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง',
    validate: (value) => {
        let result = ThaiNationalID(value);
        return result
    }
}
)
extend('thai_phonenumber', 
{
    message: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
    validate: (value) => {
      var pattern = new RegExp(
        /^[0][3-9][0-9]{4}[0-9]{4}$/
    ) // fragment locator
    return pattern.test(value)
    }
}
)


function ThaiNationalID (id) {
    if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
      return false
    }
    let i; let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
      sum += parseInt(id.charAt(i)) * (13 - i)
    }
    const check = (11 - sum % 11) % 10
    if (check === parseInt(id.charAt(12))) {
      return true
    }
    return false
  }