<template>
  <v-app
    style=" position: absolute; width: 100vw; height: 100vh; background: #EFEEF4 !important;"
  >
    <v-main class="Segoe" id="webchat">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  components: {},
  data: () => ({
    prevHeight: 0,
  }),
  mounted() {},
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
  computed: {
    bgcolor() {
      return this.$store.state.bgcolor;
    },
  },
};
</script>
<style>
#App {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.Kanit {
  font-family: "Kanit", sans-serif !important;
}
.Segoe {
  font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
  letter-spacing: 0 !important;
}
.v-btn {
  letter-spacing: 0 !important;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
  max-width: 500px;
  margin: 0 auto;
  background: linear-gradient(167.24deg, #b9e7fb -22.62%, #02259f 89.75%);
}
.wrapper-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh !important;
  background-color: #fff;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 24px 4px;
}
.grecaptcha-badge {
  display: none;
}
</style>
